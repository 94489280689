"use client";

import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

export default function Toaster({ ...props }: ToasterProps) {
  return (
    <Sonner
      closeButton
      position="bottom-right"
      richColors
      toastOptions={{
        classNames: {
          icon: "mr-4",
        },
      }}
      {...props}
    />
  );
}

export { Toaster };
